import React, { useEffect, useState } from "react";
import axios from "../../libs/axios";
import { Calendar, Badge } from "rsuite";
import PageTitle from "../../components/common/PageTitle";
import { Card, CardHeader, Row, Col, Container } from "shards-react";
import styles from "./Calendar.module.scss";

/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "rsuite/dist/rsuite.min.css";
import { getYYMMDD } from "../../utils/formatting";
import { useNavigate } from "react-router-dom";

function CalendarView() {
  const [calendar, setCalendar] = useState();

  useEffect(() => {
    axios.get("/calendar").then((res) => {
      setCalendar(res.data.data);
    });
  }, []);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const getHearingsOfDate = (date) => {
    const yymmdd = getYYMMDD(date);

    const hearings = calendar?.[yymmdd];
    if (hearings) {
      return hearings;
    }

    return [];
  };

  function renderCell(date) {
    const list = getHearingsOfDate(date);

    if (list.length) {
      return (
        <div>
          <Badge />
        </div>
      );
    }

    return null;
  }

  const selectedHearings = getHearingsOfDate(selectedDate);

  const formatDate = (d) => {
    const date = new Date(d);
    const month = date.getMonth() + 1;
    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  return (
    <div css={css``}>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={"Calendar"}
            subtitle="View upcoming hearings"
            md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Row>
        <Row>
          <Col lg="12">
            <div id="calendar">
              <Card small className="mb-4">
                <Calendar
                  bordered
                  renderCell={renderCell}
                  onSelect={(d) => {
                    setSelectedDate(d);
                  }}
                />
              </Card>
            </div>
          </Col>
        </Row>
        {selectedDate && (
          <Row>
            <Col md="12" lg="12">
              <div id="hearing-preview">
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">
                      Hearings on {formatDate(selectedDate)}
                    </h6>
                  </CardHeader>
                  <Hearings
                    hearings={selectedHearings}
                    currentDate={selectedDate}
                  />
                </Card>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default CalendarView;

function Hearings({ hearings, currentDate }) {
  const judges = hearings
    .filter((c) => {
      return !!c.case.jurisdiction;
    })
    .reduce((acc, curr) => {
      if (!curr.case.judge) {
        return acc;
      }

      if (!acc[curr.case.judge?.id]) {
        acc[curr.case.judge?.id] = {};
      }

      return {
        ...acc,
        [curr.case.judge?.id]: {
          judge: curr.case.judge,
          jurisdiction_id: curr.case.judge?.jurisdiction_id,
          jurisdiction: curr.case.jurisdiction,
          hearings: [...(acc[curr.case.judge?.id]?.hearings || []), curr],
        },
      };
    }, {});

  const withoutJudge = hearings
    .filter((hearing) => !hearing.case.judge)
    .map((hearing) => {
      return {
        judge: {},
        jurisdiction_id: null,
        jurisdiction: null,
        hearings: [hearing],
      };
    });

  const jurisdictions = Object.keys(judges).reduce((acc, curr, i) => {
    const jurisdictionJudges = acc[judges[curr.jurisdiction_id]]?.judges || [];
    return {
      ...acc,
      [judges[curr].jurisdiction_id]: {
        jurisdiction: judges[curr].jurisdiction || {
          id: i + "_unknown_jurisdiction",
          location_name: "Unknown",
        },
        judges: [...jurisdictionJudges, judges[curr]],
      },
    };
  }, {});
  jurisdictions["Without Judge"] = {
    jurisdiction: { location_name: "Without Judge" },
    judges: withoutJudge,
  };

  const jurisdictionArray = Object.keys(jurisdictions).map((jur) => {
    return {
      jurisdiction: jurisdictions[jur].jurisdiction,
      judges: jurisdictions[jur].judges,
    };
  });

  const formatDate = (d) => {
    const date = new Date(d);
    const month = date.getMonth() + 1;
    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const formatResident = (caseItem) => {
    if (!caseItem) return "";
    const { residents } = caseItem;
    const resident1 = residents[0];
    if (!resident1) {
      return "N/A";
    }
    let addString = "";

    if (resident1.first_name) {
      addString += resident1.first_name;
    }

    if (resident1.last_name) {
      addString += " " + resident1.last_name;
    }

    return addString;
  };

  const navigate = useNavigate();

  if (hearings.length === 0) {
    return <div className="p-3">No hearings on {formatDate(currentDate)}.</div>;
  }

  return (
    <div
      css={css`
        .jurisdiction {
          margin-bottom: 36px;

          .title {
            font-weight: 600;
            opacity: 1;
            color: black;
            margin: 0;
          }
        }
      `}
      className="p-3"
    >
      {jurisdictionArray.map((jur) => {
        return (
          <div key={jur.jurisdiction.id + "jur"} className="jurisdiction">
            <p className="title">{jur.jurisdiction.location_name}</p>
            <ul
              className="judges"
              css={css`
                list-style: none;
                padding: 0;
              `}
            >
              {jur.judges.length > 0 ? (
                jur.judges.map((judge) => {
                  return (
                    <li key={judge.judge.id + "judge"} css={css``}>
                      <p
                        css={css`
                          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                          padding-bottom: 14px;
                          margin-bottom: 8px;
                        `}
                      >
                        {(judge.judge.id &&
                          `${judge.judge.first_name} ${judge.judge.last_name}`) ||
                          "none"}
                      </p>
                      <table
                        css={css`
                          width: 100%;
                        `}
                      >
                        <thead>
                          <tr>
                            <th>Case Number</th>
                            <th>Management Organization</th>
                            <th>Property Owner</th>
                            <th>Resident</th>
                            <th>Trial?</th>
                            <th>Return Filed?</th>
                          </tr>
                        </thead>
                        <tbody>
                          {judge.hearings.map((hearing) => {
                            return (
                              <tr
                                key={hearing.id}
                                className={styles["card-item"]}
                                onClick={() => {
                                  navigate(
                                    `/cases/${hearing.case.id}/${hearing.case.organization_id}`
                                  );
                                }}
                              >
                                <td>{hearing.case.court_case_number}</td>
                                <td>{hearing.case.organization.name}</td>
                                <td>
                                  {hearing.case.owner.business_legal_name}
                                </td>
                                <td>{formatResident(hearing.case)}</td>
                                <td>{hearing.trial === 1 ? "Yes" : "No"}</td>
                                <td>{hearing.return_filed ? "Yes" : "No"}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </li>
                  );
                })
              ) : (
                <li>No hearings</li>
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
