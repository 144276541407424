import React, { useState } from "react";
import { CardHeader } from "shards-react";
import styles from "./card.module.scss";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getYYMMDD } from "../../utils/formatting";
import { useNavigate } from "react-router-dom";

function UpcomingHearings({ upcomingHearings }) {
  const getNextNineWeekdays = () => {
    const today = new Date();
    const dates = [];

    // no weekends
    const daysToAdd = 9;

    for (let i = 0; i <= daysToAdd; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);

      const dayOfWeek = nextDate.getDay();
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

      if (!isWeekend) {
        dates.push({
          date: getYYMMDD(nextDate),
          dayObj: nextDate,
        });
      }
    }

    return dates;
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabs = getNextNineWeekdays().map(({ date, dayObj }, idx) => {
    const dayName = dayObj.toLocaleDateString("en-US", { weekday: "long" });
    const now = new Date();
    const isActive =
      dayObj.getFullYear() === now.getFullYear() &&
      dayObj.getMonth() === now.getMonth() &&
      dayObj.getDate() === now.getDate();

    return {
      date: date,
      dayObj,
      hearings: upcomingHearings.filter((hearing) => hearing.date === date),
      dayName,
      active: isActive,
      current: currentTabIndex === idx,
    };
  });

  const currentTab = tabs[currentTabIndex];

  const getFormattedTabName = (tab) => {
    const date = tab.dayObj;
    const month = date.getMonth() + 1;
    const day = date.toLocaleDateString("en-US", { day: "numeric" });

    return `${tab.dayName} ${month}/${day}`;
  };

  return (
    <div css={css``}>
      <CardHeader className="border-bottom">
        <h6 className="m-0">Upcoming Hearings</h6>
      </CardHeader>
      <div
        css={css`
          display: flex;
          padding: 14px;
          justify-content: space-between;

          button {
            background-color: transparent;
            border: none;
            opacity: 0.75;
            font-weight: 500;

            &.today {
              opacity: 1;
            }

            &.current {
              opacity: 1;
              font-weight: 600;
              text-decoration: underline;
            }
          }
        `}
      >
        {tabs?.map(({ date, dayObj, dayName, active, current }, tabIdx) => (
          <button
            key={date}
            className={`${active ? "today" : ""} ${current ? "current" : ""}`}
            onClick={() => setCurrentTabIndex(tabIdx)}
          >
            {getFormattedTabName({ date, dayName, dayObj })}
          </button>
        ))}
      </div>
      <div
        css={css`
          padding: 18px;
        `}
      >
        <Hearings hearings={currentTab.hearings} currentTab={currentTab} />
      </div>
    </div>
  );
}

export default UpcomingHearings;

function Hearings({ hearings, currentTab }) {
  const judges = hearings
    .filter((c) => {
      return !!c.case.jurisdiction;
    })
    .reduce((acc, curr, i) => {
      if (!curr.case.judge) {
        return acc;
      }

      if (!acc[curr.case.judge?.id]) {
        acc[curr.case.judge?.id] = {};
      }

      return {
        ...acc,
        [curr.case.judge?.id]: {
          judge: curr.case.judge,
          jurisdiction_id: curr.case.judge?.jurisdiction_id,
          jurisdiction: curr.case.jurisdiction,
          hearings: [...(acc[curr.case.judge?.id]?.hearings || []), curr],
        },
      };
    }, {});

  const withoutJudge = hearings
    .filter((hearing) => !hearing.case.judge)
    .map((hearing) => {
      return {
        judge: {},
        jurisdiction_id: null,
        jurisdiction: null,
        hearings: [hearing],
      };
    });

  const jurisdictions = Object.keys(judges).reduce((acc, curr) => {
    const jurisdictionJudges = acc[judges[curr.jurisdiction_id]]?.judges || [];
    return {
      ...acc,
      [judges[curr].jurisdiction_id]: {
        jurisdiction: judges[curr].jurisdiction,
        judges: [...jurisdictionJudges, judges[curr]],
      },
    };
  }, {});
  if (withoutJudge.length > 0) {
    jurisdictions["Without Judge"] = {
      jurisdiction: { location_name: "Without Judge" },
      judges: withoutJudge,
    };
  }

  const jurisdictionArray = Object.keys(jurisdictions).map((jur, i) => {
    return {
      jurisdiction: jurisdictions[jur].jurisdiction || {
        id: i + "_unknown_jurisdiction",
        location_name: "Unknown",
      },
      judges: jurisdictions[jur].judges,
    };
  });

  const formatDate = (d) => {
    const date = new Date(d);
    const month = date.getMonth() + 1;
    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const formatResident = (caseItem) => {
    if (!caseItem) return "";
    const { residents } = caseItem;
    const resident1 = residents[0];
    if (!resident1) {
      return "N/A";
    }
    let addString = "";

    if (resident1.first_name) {
      addString += resident1.first_name;
    }

    if (resident1.last_name) {
      addString += " " + resident1.last_name;
    }

    return addString;
  };

  const navigate = useNavigate();

  if (hearings.length === 0) {
    return (
      <div className="p-3">No hearings on {formatDate(currentTab.date)}.</div>
    );
  }

  return (
    <div
      css={css`
        .jurisdiction {
          margin-bottom: 36px;

          .title {
            font-weight: 600;
            opacity: 1;
            color: black;
            margin: 0;
          }
        }
      `}
      className="p-3"
    >
      {jurisdictionArray.map((jur) => {
        return (
          <div key={jur.jurisdiction?.id + "jur"} className="jurisdiction">
            <p className="title">{jur.jurisdiction?.location_name}</p>
            <ul
              className="judges"
              css={css`
                list-style: none;
                padding: 0;
              `}
            >
              {jur.judges.map((judge) => {
                return (
                  <li key={judge.judge.id + "judge"} css={css``}>
                    <p
                      css={css`
                        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                        padding-bottom: 14px;
                        margin-bottom: 8px;
                      `}
                    >
                      {(judge.judge.id &&
                        `${judge.judge.first_name} ${judge.judge.last_name}`) ||
                        "none"}
                    </p>
                    <table
                      css={css`
                        width: 100%;
                      `}
                    >
                      <thead>
                        <tr>
                          <th>Case Number</th>
                          <th>Management Organization</th>
                          <th>Property Owner</th>
                          <th>Resident</th>
                          <th>Trial?</th>
                          <th>Return Filed?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {judge.hearings.map((hearing) => {
                          return (
                            <tr
                              key={hearing.id}
                              onClick={() => {
                                navigate(
                                  `/cases/${hearing.case.id}/${hearing.case.organization_id}`
                                );
                              }}
                              className={styles["card-item"]}
                            >
                              <td>{hearing.case.court_case_number}</td>
                              <td>{hearing.case.organization.name}</td>
                              <td>{hearing.case.owner.business_legal_name}</td>
                              <td>{formatResident(hearing.case)}</td>
                              <td>{hearing.trial === 1 ? "Yes" : "No"}</td>
                              <td>{hearing.return_filed ? "Yes" : "No"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
